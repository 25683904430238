import { ContactContext } from 'src/contexts/contactContext'
import session from 'src/session'
import CountryContext from 'src/contexts/countryContext'
import { Button, Divider, Dropdown, Space, notification } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuProps } from 'antd/lib'
import { useMutation, useQuery } from '@apollo/client'
import { CONTACTS_QUERY, CONTACT_SELECT_MUTATION } from '@/graphql/Contact'
import { CheckOutlined } from '@ant-design/icons'

const DropdownContact = () => {
  const { t } = useTranslation()
  const { contact } = useContext(ContactContext)
  const { countryCode } = useContext(CountryContext)
  const { data, loading, error } = useQuery(CONTACTS_QUERY)
  const [selectContact] = useMutation(CONTACT_SELECT_MUTATION)

  const handleDisconnect = () => {
    session.clearSession()
    const baseUrl = process.env['REACT_APP_HOME_LINK_' + countryCode]
    window.location.href = `${baseUrl}/sessions/deconnexion`
  }

  const switchContact = async (contactId: number) => {
    try {
      const { data } = await selectContact({ variables: { contactId: contactId } })
      if (data.error) {
        notification.error({
          message: t('common:error'),
          description: data.error.message,
          duration: 5
        })
      } else if (data.contactSelect?.token) {
        session.refreshSession(data.contactSelect.token)
        window.location.reload()
      }
    } catch (error) {
      notification.error({
        message: t('common:error'),
        description: t('common:error'),
        duration: 5
      })
    }
  }

  const items = data?.contacts
    ?.filter((listedContact) => listedContact.id !== contact.id)
    .map((listedContact) => {
      return {
        label: `${listedContact?.society.name ?? 'Mon compte'} ${
          contact.domiciliationStatus && t(`nav:accounts.${listedContact.domiciliationStatus}`)
        }`,
        key: listedContact?.society.id ?? 0,
        icon: listedContact.id === contact.id ? <CheckOutlined /> : '',
        onClick: () => {
          switchContact(listedContact.id)
        }
      }
    }) as MenuProps['items']

  const onlyOneAccount = items?.length === 0

  if (error) {
    return <span>{`${t('common:error')}! ${error.message}`}</span>
  } else {
    return (
      <Dropdown
        menu={{ items }}
        className='text-neutral-700'
        overlayStyle={
          onlyOneAccount ? {} : { background: 'white', boxShadow: '0 5px 20px 0 #0000001a', borderRadius: '8px' }
        }
        dropdownRender={(menu) => (
          <div className='w-full'>
            {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: 'none', background: 'none' } })}
            <Divider style={{ margin: 0 }} />
            <Space className='p-1 w-full [&_.ant-space-item]:w-full'>
              <Button className='primary-button w-full' type='primary' onClick={handleDisconnect}>
                {t('common:disconnect')}
              </Button>
            </Space>
          </div>
        )}
      >
        <Button
          className='!bg-neutral-200 !text-neutral-700 w-full max-w-[210px] flex justify-between items-center'
          loading={loading}
        >
          {loading ? 'Mon compte' : contact.society.name}
          <span className='ico-caret-down text-5' />
        </Button>
      </Dropdown>
    )
  }
}

export default DropdownContact
